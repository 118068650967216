import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    changeWhen: String,
    changeTextTo: String
  }

  connect() {
    const form = this.element.closest("form")
    this.submitButton = form.querySelector("input[type='submit']")
    this.defaultSubmitButtonText = this.submitButton.value
  }

  handleChange(event) {
    const { target: { value, checked, type: fieldType } } = event

    let shouldToggleText = value == this.changeWhenValue

    if (fieldType == "checkbox") {
      const booleanToggleWhenValue = this.changeWhenValue == "true"
      shouldToggleText = checked == booleanToggleWhenValue
    }

    const form = this.element.closest("form")
    const submitButton = form.querySelector("input[type='submit']")

    submitButton.value = shouldToggleText ? this.changeTextToValue : this.defaultSubmitButtonText
  }
}
