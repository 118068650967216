import { Controller } from "@hotwired/stimulus"

import TomSelect from "tom-select"

export default class extends Controller {
	static values = {
		identifier: String,
		apiUrl: String,
		isRequired: Boolean,
		default: String,
		// set to true if you want to pre-fetch dropdown records on the initialization of the dropdown
		isRemoteSearchDisabled: Boolean,
		// allow users to create new items if it doesn't exist in the dropdown
		allowCreate: Boolean,
		broadcastChange: Boolean
	}

	connect() {
		const x = this
		let options = []
		let items = []

		if (this.defaultValue) {
			options = [{ value: this.defaultValue, text: this.defaultValue }]
			items = [this.defaultValue]
		}

		this.tomSelect = new TomSelect(`#${x.identifierValue}`, {
			allowEmptyOption: !x.isRequiredValue,
			plugins: ['clear_button'],
			sortField: {
				field: "text",
				direction: "asc"
			},
			highlight: false,
			options,
			items,
			create: x.allowCreateValue,
			onInitialize: function () {
				if (!x.isRemoteSearchDisabledValue) return

				fetch(x.apiUrlValue)
					.then(response => response.json())
					.then(records => {
						x.tomSelect.addOptions(records)

						if (x.defaultValue) {
							x.tomSelect.addItem(x.defaultValue)
						}
					}).catch((e) => {
						console.log("Async Dropdown - Error while fetching records")
						console.log(e)
					})
			},
			load: function (query, callback) {
				if (x.isRemoteSearchDisabledValue) {
					callback()

					return
				}

				var url = `${x.apiUrlValue}?q=` + encodeURIComponent(query)

				fetch(url)
					.then(response => response.json())
					.then(records => {
						callback(records)
					}).catch((e) => {
						console.log("Async Dropdown - Error while searching records")
						console.log(e)
						callback()
					})
			},
			onChange: function (value) {
				if (!x.broadcastChangeValue) return

				x.broadcastChange(value)
			}
		})
	}

	broadcastChange(value) {
		const item = this.tomSelect.options[value]

		this.dispatch("change", { detail: { value, text: item?.text, id: item?.id } })
	}
}
