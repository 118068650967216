import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    resetWhen: String,
  }
  static targets = ["resettableField"]

  handleChange(event) {
    const { target: { value, checked, type: fieldType } } = event

    let shouldResetFields = value == this.resetWhenValue

    if (fieldType == "checkbox" || fieldType == "radio") {
      const booleanResetWhenValue = this.resetWhenValue == "true"
      shouldResetFields = checked == booleanResetWhenValue
    }

    if (!shouldResetFields) {
      return
    }

    this.resettableFieldTargets.forEach((element) => {
      if (element.dataset.triggeringField != event.target.id) {
        return
      }

      if (element.type == "checkbox" || element.type == "radio") {
        element.checked = element.dataset.defaultValue || false
      } else {
        element.value = element.dataset.defaultValue || ""
      }
    })
  }
}
