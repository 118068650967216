import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  handleChange({ detail: { id } }) {
    const idElement = document.getElementById("saved_search_id")
    const formSubmitButtonElement = document.getElementById("saved_search_submit_button")

    idElement.value = null

    if (id) {
      idElement.value = id
      formSubmitButtonElement.value = "Update existing search"
    } else {
      formSubmitButtonElement.value = "Create new search"
    }
  }
}
