import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    identifier: String,
  }
  static targets = ["rowDetail"]

  handleChange(event) {
    const identifier = event.target.dataset.identifier
    const detailRow = this.rowDetailTargets.find(target => target.id == identifier)

    if (detailRow) {
      detailRow.classList.toggle("hidden")

      const iconButton = event.target
      const icon = event.target.querySelector("i")

      icon.classList.toggle("fa-chevron-down")
      icon.classList.toggle("fa-chevron-up")
    }
  }
}
