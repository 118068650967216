import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "content"]

  handleChange(event) {
    const targetElement = event.target
    const targetContentElement = this.contentTargets.find((content) => content.dataset.identifier === targetElement.dataset.identifier)

    const activeTab = this.tabTargets.find((tab) => tab.classList.contains("active"))
    const activeTabElement = this.contentTargets.find((content) => content.dataset.identifier === activeTab.dataset.identifier)

    targetElement.classList.add("active")
    activeTab.classList.remove("active")

    activeTabElement.classList.add("hidden")    
    targetContentElement.classList.remove("hidden")
  }
}
