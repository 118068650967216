import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    hideWhen: String,
    fields: Array
  }

  handleChange(event) {
    const { target: { value, checked, type: fieldType } } = event

    let shouldHideFields = value == this.hideWhenValue

    if (fieldType == "checkbox") {
      const booleanHideWhenValue = this.hideWhenValue == "true"
      shouldHideFields = checked == booleanHideWhenValue
    }

    this.fieldsValue.forEach((fieldId) => {
      if (shouldHideFields) {
        document.getElementById(fieldId).classList.add("hidden")
      } else {
        document.getElementById(fieldId).classList.remove("hidden")
      }
    })
  }
}
