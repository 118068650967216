import { Controller } from "@hotwired/stimulus"

import TomSelect from "tom-select"

export default class extends Controller {
	static values = {
		identifier: String,
		apiUrl: String,
		isRequired: Boolean,
		default: String,
		// set to true if you want to pre-fetch dropdown records on the initialization of the dropdown
		isRemoteSearchDisabled: Boolean
	}

	connect() {
		const x = this
		let options = []
		let items = []

		if (this.defaultValue) {
			const values = this.defaultValue.includes("[") ? JSON.parse(this.defaultValue) : [this.defaultValue]

			values.forEach((value) => {
				options.push({ value, text: value })
				items.push(value)
			})
		}

		this.tomSelect = new TomSelect(`#${x.identifierValue}`, {
			allowEmptyOption: !x.isRequiredValue,
			plugins: ['remove_button'],
			sortField: {
				field: "text",
				direction: "asc"
			},
			highlight: false,
			options,
			items,
			onInitialize: function () {
				if (!x.isRemoteSearchDisabledValue) return

				fetch(x.apiUrlValue)
					.then(response => response.json())
					.then(records => {
						x.tomSelect.addOptions(records)

						if (x.defaultValue) {
							x.tomSelect.addItem(x.defaultValue)
						}
					}).catch((e) => {
						console.log("Async Autocomplete Dropdown - Error while fetching records")
						console.log(e)
					})
			},
			load: function (query, callback) {
				if (x.isRemoteSearchDisabledValue) return

				var url = `${x.apiUrlValue}?q=` + encodeURIComponent(query)

				fetch(url)
					.then(response => response.json())
					.then(records => {
						callback(records)
					}).catch((e) => {
						console.log("Async Autocomplete Dropdown - Error while searching records")
						console.log(e)
						callback()
					})
			},
		})
	}
}
