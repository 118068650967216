import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    identifier: String,
  }
  static targets = ["plans", "button"]

  handleChange() {
    this.buttonTargets.forEach((el) => el.classList.toggle("button-active"))
    this.plansTargets.forEach((el) => el.classList.toggle("hidden"))
  }
}
