import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { submitType: String }

  updateBrowserUrl(event) {
    if (!event.detail.success) return

    const newUrl = this.submitTypeValue == "reset" ? window.location.pathname : event.detail.fetchResponse.response.url

    window.history.pushState({}, '', newUrl)
  }

  handleClick(event) {
    event.preventDefault()
    const url = event.currentTarget.href

    fetch(url, { headers: { "Accept": "text/vnd.turbo-stream.html" } })
      .then(response => {
        response.text().then(html => {
          Turbo.renderStreamMessage(html)
          window.history.pushState({}, '', url)
        })
      })
      .catch(error => {
        console.error("Error fetching the URL:", error)
      })
  }
}
