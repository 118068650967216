import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "mobileContainer", "button", "mobileButton", "form"]

  toggle(event) {
    if (this.isMobileBreakpoint(event)) {
      this.formTarget.classList.toggle("hidden")
      this.mobileButtonTargets.forEach((el) => el.classList.toggle("hidden"))
    } else {
      this.containerTarget.classList.toggle("hidden")
      this.containerTarget.classList.toggle("lg:block")
      this.buttonTargets.forEach((el) => el.classList.toggle("hidden"))
    }
  }

  private

  isMobileBreakpoint(event) {
    return event.currentTarget.parentElement.classList.contains("lg:hidden")
  }
}
